import React, { Component } from "react"
import queryString from 'query-string'
import styled from "styled-components"
import { navigate } from "gatsby"
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl"


const Form = styled.form`
    width: 100%;
    margin-bottom: 0;
    p {
      font-size: 1rem;
      line-height: 1.4;
      margin-bottom: 2rem;
      font-style: italic;
    }
`

const FormContain = styled.div`
    padding: 1.4rem;
    width: 100%;
    background-color: #fff;
    max-width: 700px;
    margin: 0 auto;
    font-family: Open Sans, sans-serif;
`

const Input = styled.input`
    display: block;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px 5px 5px 5px; 
    padding: .5rem 1rem; 
    font-size: 1rem;
    margin-bottom: 2.5rem;
    margin-top: .5rem;
`

const Label = styled.label`
    display: block;    
    font-size: 1rem;
    font-weight: 600;
`

const Button = styled.button`
  display: block;
  padding: .5rem;
  font-size: 24px;
  font-weight: 700;
  border: none;
  border-radius: 5px 5px 5px 5px; 
  margin-bottom: 3rem;
 
  background-color: #000000;
  color: #ffffff;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:hover {
      background-color:#222222;
      color: #eae56a;
      transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  }
  @media (max-width: 1100px) {
      font-size: 14px;
  }
`

class QuestionnaireForm extends Component {
  constructor(props) {
    super(props)
    this.ContactForm = React.createRef()
    this.state = {
      phone: "",
      email: "",
      firstname: "",
      lastname: "",
    }
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = this.ContactForm.current
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(response => {
        const { intl } = this.props
        navigate(`${intl.locale}/success`)
      })
      .catch(error => {
        console.log("====================================")
        console.log(`error in submiting the form data:${error}`)
        console.log("====================================")
      })
  }

  componentDidMount() {
    const parsed = queryString.parse(window.location.search)
    this.setState({ phone: (parsed.phone || "") })
  }

  render() {
    const { intl } = this.props
    const { phone, firstname, lastname, email } = this.state

    return (
      <Form
        name="latinext-whatsapp-questionnaire"
        method="post"
        action={`/${intl.locale}/success`}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        ref={this.ContactForm}
      >
        <input type="hidden" name="form-name" value="electoraid-questionnaire" />
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>

        <FormContain>

          <p><FormattedHTMLMessage id="questionnaireform.intro"/></p>
          
          <Label htmlFor="phone"><FormattedMessage id="questionnaireform.phone.label" /></Label>
          <Input required type="text" name="phone" value={phone} onChange={this.handleChange} placeholder={intl.formatMessage({id: "questionnaireform.phone.placeholder"})}></Input>
          
          <Label htmlFor="firstname"><FormattedMessage id="questionnaireform.firstname.label" /></Label>
          <Input required type="text" name="firstname" value={firstname} onChange={this.handleChange} placeholder={intl.formatMessage({id: "questionnaireform.firstname.placeholder"})}></Input>

          <Label htmlFor="lastname"><FormattedMessage id="questionnaireform.lastname.label" /></Label>
          <Input required type="text" name="lastname" value={lastname} onChange={this.handleChange} placeholder={intl.formatMessage({id: "questionnaireform.lastname.placeholder"})}></Input>
          
          <Label htmlFor="email"><FormattedMessage id="questionnaireform.email.label" /></Label>
          <Input required type="email" name="email" value={email} onChange={this.handleChange} placeholder={intl.formatMessage({id: "questionnaireform.email.placeholder"})}></Input>
          
          <Button type="submit"><FormattedMessage id="questionnaireform.submit"/></Button>
        </FormContain>
      </Form>
    )
  }
}
export default injectIntl(QuestionnaireForm)
